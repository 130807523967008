@font-face {
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url(/public/assets/fonts/poppins-regular.woff2) format("woff2");
}
@font-face {
  font-family: "Poppins Medium";
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: local("Poppins Medium"), local("Poppins-Medium"),
    url(/public/assets/fonts/poppins-medium.woff2) format("woff2");
}
@font-face {
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: local("Poppins Bold"), local("Poppins-Bold"),
    url(/public/assets/fonts/poppins-bold.woff2) format("woff2");
}

body {
  font-family: "Poppins Regular" !important;
}
